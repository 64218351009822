import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ContentLayout from '../../../components/ContentLayout';
import AcademyNavigation from '../../../components/academy/AcademyNavigation';
import TuitionNavigation from '../../../components/academy/TuitionNavigation';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <ContentLayout title="Admissions" subTitle="Requirements" nav={<AcademyNavigation />} subNav={<TuitionNavigation />}>
    {children}
  </ContentLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Hardware Requirements`}</h3>
    <p>{`A laptop computer is required for our program. The suggested computer for students is an Apple MacBook Pro laptop less than two years old. A PC laptop with the Windows operating system may also be preffered by some students.`}</p>
    <p>{`Suggested Specifications:`}</p>
    <p>{`8GB of RAM
250GB HDD or SSD
Latest macOS or Windows Operating Systems`}</p>
    <p><em parentName="p">{`Why do we prefer Macs?`}</em>{` All of our students being on the same platform enables us to move at a much quicker pace. Processes like setting up new tools experience reduced friction because the same workflows apply to everyone.`}</p>
    <p>{`Additionally, The open source and web development communities are invested in tools and libraries that work best with POSIX based operating systems like UNIX, Linux, and Apple’s macOS (BSD Unix). In short, we believe using the macOS best prepares our students to work in the web development industry.`}</p>
    <p>{`Students who choose to study .NET development with us may also find substantial benefit from using macOS. `}</p>
    <h3>{`Software Requirements`}</h3>
    <p>{`The tools and materials used in our program are open source and free. No additional software is required.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      